<template>
  <div class="contaienr" v-if="loading">
    <div>
      <img class="contaienr-gif" src="./img/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      required: true,
      type: Boolean
    }
  },

  watch: {
    loading (Boolean) {
      if (Boolean) {
        setTimeout(() => {
          this.$emit('closeLoading')
        }, 6000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.contaienr {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 1.2432rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &-gif {
    width: 1.0811rem;
  }
}
</style>