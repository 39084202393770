<template>
  <div class="borderContainer">
    <van-nav-bar 
      title="养生科技馆" 
      fixed 
      placeholder 
      @click-left="onClickLeft" 
      left-arrow />

    <div class="container">
      <div class="container-swipe">
        <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
          <van-swipe-item v-for="(x, i) in picUrls" :key="i">
            <van-image lazy-load fit="cover" :src="x" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="shangpin box">
      <p class="shangpin-name">{{ name }}</p>
      <div class="shangpin-zhifuTy">
        <div class="shangpin-zhifuTy-item" v-for="(x, i) in virtualGoodsPayNmae" :key="x.type">
          <span class="shangpin-zhifuTy-item-text">{{ x.name }}：{{ x.jine }}</span>
          <span class="shangpin-zhifuTy-item-shu" v-if="i != virtualGoodsPayNmae.length -1">|</span>
        </div>
      </div>
      <div class="shangpin-price">累计兑换{{ saleNum }}件</div>
    </div>

    <div class="jine box">
      <div class="jine-title">服务说明</div>
      <div class="jine-text">1.支持7天内退订，超过7天不支持退订操作</div>
      <div class="jine-text">2.拍下后请在有效期内使用，超过有效期限，此项服务则失效，不可用。</div>
    </div>

    <div class="shopStore box">
      <div class="shopStore-left">
        <van-image
          width="30"
          height="30"
          fit="cover"
          :src="shopInfo.imgUrl"
        />
        <span class="shopStore-left-name">{{ shopInfo.name }}</span>
      </div>
      <div class="shopStore-right" @click="enterStore">
        <span class="shopStore-right-text">进入店铺</span>
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="southDetail">
      <img class="southDetail-img" src="./img/detail.png" alt="">
      <div v-html="description"></div>
    </div>

    <van-goods-action>
      <van-goods-action-icon icon="service-o" text="客服" @click="contact" />
      <van-goods-action-button color="#E54320" text="立即购买" @click="duihuan" />
    </van-goods-action>

    <van-action-sheet v-model="zhifuType" title=" " style="height: ;">
      <div class="content">
        <div class="content-shopDetail">
          <van-image
            width="100"
            height="100"
            fit="cover"
            :src="imgUrl"
          />
          <div class="content-shopDetail-right">
            <p class="content-shopDetail-right-name">{{ name }}</p>
            <span>
              <van-stepper disable-input v-model="quantity" integer />
            </span>
          </div>
        </div>

        <div class="fenxiaoma">
          <van-cell-group>
            <van-field
              @input="input"
              maxlength="8" 
              v-model="distributionCode" 
              placeholder="请输入文旅分销码" />
          </van-cell-group>
        </div>

        <van-tabs v-model="active">
          <van-tab 
            v-for="x in virtualGoodsPay" 
            :key="x" 
            :name="x" 
            :title="x=='1'?'金叶子':x=='2'?'驿马':x =='3'?'官凭':x =='4'?'现金':''"></van-tab>
        </van-tabs>

        <p class="content-zhifu">需支付</p>

        <div class="content-jine" v-if="active == '1'">
          <span class="content-jine-num">{{ goldLeaf * quantity }} </span>
          <span class="content-jine-type">金叶子</span>
        </div>

        <div class="content-jine" v-if="active == '2'">
          <span class="content-jine-num">{{ postHorse * quantity }} </span>
          <span class="content-jine-type">驿马</span>
        </div>

        <div class="content-jine" v-if="active == '3'">
          <span class="content-jine-num">{{ officialCertificate * quantity }} </span>
          <span class="content-jine-type">官凭</span>
        </div>

        <div class="content-jine" v-if="active == '4'">
          <span class="content-jine-fuhao">￥</span>
          <span class="content-jine-xianjin">{{ salesPrice * quantity }}</span>
        </div>

        <div style="margin-top: 20px;">
          <van-button round block color="#E54320" @click="confirmPayment">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="selectBoolean" title="选择账户">
      <div class="selectUser">
        <div class="paymentType">
          <div class="paymentType-item border-btn" v-for="x in shopJoinUser" :key="x.id"
            @click="paymentType(x.type, x.available, x.id)">
            <img style="width: 0.4865rem;" :src="x.icon" alt="">
            <span class="paymentType-item-type">{{ x.nameType }}</span>
            <span class="paymentType-item-hongmi">余额：{{ x.available + '金叶子' }}</span>
            <span>
              <van-icon v-show="x.type != type" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="x.type == type" name="checked" color="#FB2B53" size="18" />
            </span>
          </div>
        </div>

        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="goldLeaf_zhifu">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="xianjinBoolean" title="现金支付">
      <div class="selectUser">
        <div>
          <div class="paymentType">
            <div class="paymentType-item border-btn" v-for="x in xianjin_zhifu" :key="x.id"
              @click="xianjinPaymentType(x.type, x.available, x.id)">
              <img style="width: 0.4865rem;" :src="x.icon" alt="">
              <span class="paymentType-item-type">{{ x.nameType }}</span>
              <span class="paymentType-item-hongmi"></span>
              <span>
                <van-icon v-show="x.type != xianjinType" name="checked" color="#b7b7b7" size="18" />
                <van-icon v-show="x.type == xianjinType" name="checked" color="#FB2B53" size="18" />
              </span>
            </div>
          </div>
        </div>

        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="salesPrice_zhifu">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />

    <pay-success :paySuccessBoolean="paySuccessBoolean"/>
  </div>
</template>

<script>

import { getTravelPage, isHonemi, getUserMoney, getGuishiOrder } from '@/api/Ge/port'
import { fuli_yundian_pay } from '@/common'
import { contact } from "@/common"
import Loading from './Loading'
import PaySuccess from './PaySuccess'
import { Toast } from 'vant'

export default {
  components: { Loading, PaySuccess },

  data () {
    return {
      picUrls: [],
      name: '',
      saleNum: '',
      imAccount: '',
      virtualGoodsPay: [],
      virtualGoodsPayNmae: [],
      shopJoinUser: [],
      xianjin_zhifu: [],
      shopInfo: {},
      zhifuType: false,
      selectBoolean: false,
      xianjinBoolean: false,
      active: '',
      goldLeaf: 0,
      postHorse: 0,
      officialCertificate: 0,
      salesPrice: 0,
      type: '-1',
      xianjinType: '7',
      YiMa: 0,
      GuanPing: 0,
      loading: false,
      paySuccessBoolean: false,
      quantity: 0,
      imgUrl: '',
      distributionCode: '',
      shopId: '',
      description: ''
    }
  },

  watch: {
    zhifuType (Boolean) {
      if (!Boolean) {
        this.active = this.virtualGoodsPay[0]
      }
    },

    selectBoolean (Boolean) {
      if (!Boolean) {
        this.type = '-1'
      }
    },

    xianjinBoolean (Boolean) {
      if (!Boolean) {
        this.xianjinType = '7'
      }
    },

    paySuccessBoolean (Boolean) {
      if (!Boolean) {
        this.onClickLeft()
      }
    }
  },
  
  created () {
    this.$store.commit('setSession', this.$route.query.session)
    this.getTravelPage()
    this.isHonemi()
    this.getUserMoney()
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    window.paySuccess = this.paySuccess
  },

  methods: {
    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    contact () {
      if (this.imAccount) {
        contact(this.imAccount)
      } else {
        Toast('暂无客服')
      }
    },

    duihuan () {
      this.zhifuType = true
    },

    confirmPayment () {
      this.zhifuType = false
      if (this.active == '1') {
        this.selectBoolean = true
      } else if (this.active == '2') {
        let postHorse = this.postHorse * this.quantity
        if (postHorse  > this.YiMa) {
          Toast('驿马不足')
          return
        } else {
          let data = {
            virtualGoodsPay: '2',
            spuId: this.$route.query.id,
            quantity: this.quantity
          }
          if (this.distributionCode) {
            if (this.distributionCode.length == 8) {
              data.distributionCode = this.distributionCode
            } else {
              Toast('分销码格式不正确')
              return
            }
          }
          this.getGuishiOrder(data)
        }
      }  else if (this.active == '3') {
        let officialCertificate = this.officialCertificate * this.quantity
        if (officialCertificate > this.GuanPing) {
          Toast('官凭不足')
          return
        } else {
          const data = {
            virtualGoodsPay: '3',
            spuId: this.$route.query.id,
            advertisementId: this.$route.query.advertisementId || '',
            advertisementType:this.$route.query.advertisementType || '0'
          }
          this.getGuishiOrder(data)
        }
      } else if (this.active == '4') {
        this.xianjinBoolean = true
      }
    },

    goldLeaf_zhifu () {
      if (this.type == '-1') {
        Toast('请选择金叶子方式')
        return
      }
      let obj = this.shopJoinUser.find(x => x.type == this.type)
      let available = Number(obj.available)
      let joinId = obj.id
      let spuId = this.$route.query.id
      let goldLeaf = this.goldLeaf * this.quantity
      if ( goldLeaf > available) {
        Toast('金叶子不足')
      } else {
        let data = {
          virtualGoodsPay: '1',
          joinId,
          spuId,
          quantity: this.quantity
        }
        if (this.distributionCode) {
          if (this.distributionCode.length == 8) {
            data.distributionCode = this.distributionCode
          } else {
            Toast('分销码格式不正确')
            return
          }
        } 
        this.getGuishiOrder(data)
      }
    },

    salesPrice_zhifu () {
      const spuId = this.$route.query.id
      if (this.distributionCode) {
        if (this.distributionCode.length == 8) {
          // this.weixinPay(spuId, this.quantity, this.distributionCode)
          this.zhifubaoPay(spuId, this.quantity, this.distributionCode)
        } else {
          Toast('分销码格式不正确')
        }
      } else {
        // this.weixinPay(spuId, this.quantity, this.distributionCode)
        this.zhifubaoPay(spuId, this.quantity, this.distributionCode)
      }
    },

    paymentType (type) {
      this.type = type
    },

    xianjinPaymentType (type) {
      this.xianjinType = type
    },

    closeLoading () {
      this.closeLoading = false
    },

    paySuccess () {
      this.loading = false
      this.selectBoolean = false
      this.paySuccessBoolean = true
    },

    getUserMoney () {
      getUserMoney().then(res => {
        if (res.data.code === 0) {
          this.YiMa = res.data.data.YiMa
          this.GuanPing = res.data.data.GuanPing
        }
      })
    },

    getGuishiOrder (data) {
      this.loading = true
      getGuishiOrder(data).then(res => {
        if (res.data.code === 0) {
          this.paySuccess()
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    enterStore () {
      this.$router.push({
        path: '/travelStoreDetail',
        query: {
          session: this.$route.query.session,
          shopId: this.shopId,
          imgUrl: this.shopInfo.imgUrl,
          name: this.shopInfo.name,
          imAccount: this.imAccount
        }
      })
    },

    input (dis) {
      let dis_res = dis.replace(/\s*/g, '')
      this.distributionCode = dis_res
    },

    weixinPay (spuId, quantity, distributionCode) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.wechatPay.postMessage(`spuId=${spuId}&quantity=${quantity}&distributionCode=${distributionCode}&requesetType=19&session=${this.$route.query.session}`)
      }
      if (isAndroid) {
        window.android.wxpay(`spuId=${spuId}&quantity=${quantity}&distributionCode=${distributionCode}&requesetType=19&session=${this.$route.query.session}`)
      }
    },

    zhifubaoPay (spuId, quantity, distributionCode) {
      getGuishiOrder({ spuId, quantity, distributionCode, type: '2', virtualGoodsPay: '4' }).then(res => {
        let data = {
          data: res.data.data.body
        }
        if (res.data.code === 0) {
          fuli_yundian_pay(data, '2')
        } else {
          Toast(res.data.message)
        }
        this.xianjinBoolean = false
      }).catch(() => {
        Toast('服务繁忙，请稍后重试')
        this.xianjinBoolean = false
      })
    },

    getTravelPage () {
      getTravelPage({ id: this.$route.query.id }).then(res => {
        if (res.data.code === 0) {
          const info = res.data.data
          this.picUrls = res.data.data.picUrls
          this.name = res.data.data.name
          this.saleNum = res.data.data.saleNum
          this.imAccount = res.data.data.imAccount
          this.description = res.data.data.description
          let virtualGoodsPay = res.data.data.virtualGoodsPay.split(',')
          this.virtualGoodsPay = virtualGoodsPay
          this.active = this.virtualGoodsPay[0]
          let virtualGoodsPayNmae = []
          virtualGoodsPay.includes('1') ? virtualGoodsPayNmae.push({ type: 1, name: '金叶子', jine: info.goldLeaf }) : ''
          virtualGoodsPay.includes('2') ? virtualGoodsPayNmae.push({ type: 2, name: '驿马', jine: info.postHorse }) : ''
          virtualGoodsPay.includes('3') ? virtualGoodsPayNmae.push({ type: 3, name: '官凭', jine: info.officialCertificate }) : ''
          virtualGoodsPay.includes('4') ? virtualGoodsPayNmae.push({ type: 4, name: '现金', jine: info.salesPrice }) : ''
          this.virtualGoodsPayNmae = virtualGoodsPayNmae
          this.shopInfo = res.data.data.shopInfo
          this.goldLeaf = res.data.data.goldLeaf
          this.postHorse = res.data.data.postHorse
          this.officialCertificate = res.data.data.officialCertificate
          this.salesPrice = res.data.data.salesPrice 
          this.imgUrl = res.data.data.imgUrl
          this.shopId = res.data.data.shopId
        }
      })
    },

    isHonemi () {
      isHonemi().then(res =>  {
        if (res.data.code === 0) {
          res.data.data.shopJoinUser.forEach(x => {
            if (['0', '4'].includes(x.type)) {
              x.icon = require('./img/quanyi.png')
              x.nameType = '清澈淡远账户'//权益人
            } else if (['1'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '超凡入圣账户'//发起人
            } else if (['5'].includes(x.type)) {
              x.icon = require('./img/pu.png')
              x.nameType = '普通帐户'
            } else if (['2'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '扶危济困账户'//发起人
            } else if (['3'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '莫问出身账户'//发起人
            }
          })

          this.shopJoinUser = res.data.data.shopJoinUser

          let xianjin_zhifu = [
            // {
            //   id: '6',
            //   type: '6',
            //   available: '6',
            //   cashWithdrawable: '6',
            //   nameType: '微信',
            //   icon: require('./img/weixin.png')
            // },
            {
              id: '7',
              type: '7',
              available: '7',
              cashWithdrawable: '7',
              nameType: '支付宝',
              icon: require('./img/zhifubao.png')
            }
          ]
          this.xianjin_zhifu = xianjin_zhifu
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.borderContainer {
  background: #f6f6f6;
  min-height: 100%;
  padding-bottom: 60px;
}
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  .van-nav-bar__left {
    padding: 0 15px;
  }
}
.my-swipe .van-swipe-item {
  aspect-ratio: 2/1;
  // height: 375px;
  .van-image {
    width: 100%;
    height: 100%;
  }
}
.box {
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 10px;
}
.shangpin {
  &-name {
    font-size: 17px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  &-zhifuTy {
    padding: 10px 0;
    color: #E54320;
    font-size: 14px;
    display: flex;
    align-items: center;
    &-item {
      &-shu {
        margin: 0 10px;
      }
    }
  }
  &-price {
    font-size: 12px;
    color: #999;
  }
}
.southDetail {
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  &-img {
    width: 70%;
    margin: auto;
    vertical-align: middle;
  }
}
.jine {
  &-title {
    font-size: 14px;
  }
  &-text {
    font-size: 12px;
    color: #777;
  }
}
.shopStore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-left {
    display: flex;
    align-items: center;
    &-name {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  &-right {
    &-text {
      font-size: 14px;
      color: #333;
      margin-right: 5px;
    }
  }
}
.fenxiaoma {
  margin-bottom: 15px;
  .van-cell {
    background: #ebedf0;
    border-radius: 5px;
  }
}
.content {
  padding: 25px;
  box-sizing: border-box;
  &-shopDetail {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 20px;
      &-name {
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  &-zhifu {
    text-align: center;
    margin: 20px 0;
  }
  &-jine {
    text-align: center;
    &-num {
      color: #E54320;
      font-size: 25px;
    }
    &-type {
      font-size: 18px;
    }
    &-fuhao {
      color: #E54320;
      font-size: 14px;
    }
    &-xianjin {
      color: #E54320;
      font-size: 25px;
    }
  }
}
.selectUser {
  padding: 25px;
  box-sizing: border-box;
  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 0.3243rem 0;
      &-type {
        flex: 1;
        font-size: 0.3784rem;
        color: #333;
        margin-left: 0.2703rem;
      }
      &-hongmi {
        color: #F52556;
        font-size: 0.3784rem;
        margin-right: 0.2703rem;
      }
    }
    .border-btn {
      border-bottom: 1px solid #F6F6F6;
    }
  }
}
</style>